import React, { useState } from "react";
import "./App.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import PrayerTimeBoxes from "./components/prayer-time-boxes";
import Header from "./components/header";
import PrayerTimeCal from "./components/prayer-time-cal";
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="cami_logo" src="cami_logo.png" />
      </View>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      label: "kullanıcı adı",
      placeholder: "kullanıcı adınızı girin",
    },
    password: {
      label: "şifre:",
      placeholder: "şifrenizi girin",
    },
  },
};

function App() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    const elem = document.documentElement;
    if (!isFullscreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <Authenticator
      variation="modal"
      hideSignUp={true}
      formFields={formFields}
      components={components}
    >
      <Container fluid className="main" onClick={handleFullscreen}>
        <Header></Header>

        <PrayerTimeBoxes></PrayerTimeBoxes>
      </Container>
    </Authenticator>
  );
}

export default App;
