/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_YSVjOOQEA",
    "aws_user_pools_web_client_id": "5vp9d95ek71fi8rfraavc2pis0",
};


export default awsmobile;
