import React, { useState, useEffect } from "react";
import { Image, Row, Col } from "react-bootstrap";
import "../styles/header.css";
import { useAuthenticator } from "@aws-amplify/ui-react";

type MosqueInfo = {
  name: string;
  ownLogo: boolean;
  logoName: string;
  ditib: boolean;
};
function Header() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const { user } = useAuthenticator((context) => [context.user]);
  const [displayName, setDisplayName] = useState("");
  const [ownLogoTitle, setOwnLogoTitle] = useState("");
  const [mosqueObject, setMosqueObject] = useState<MosqueInfo>();

  const mosqueNames: { [key: string]: MosqueInfo } = {
    elmshorn: {
      name: "Hacı Bayram Veli Camii",
      ownLogo: false,
      logoName: "",
      ditib: true,
    },
    bergedorf: {
      name: "Bergedorf Kocatepe Camii",
      ownLogo: true,
      logoName: "bergedorf_logo.png",
      ditib: true,
    },
    "ayasofya-wilhelmsburg": {
      name: "Ayasofya Moschee",
      ownLogo: true,
      logoName: "ayasofya_wilhelmsburg_logo.png",
      ditib: false,
    },
  };

  useEffect(() => {
    if (user && user.username) {
      const camiName: string = user.username;
      if (camiName in mosqueNames) {
        setMosqueObject(mosqueNames[camiName])
        setDisplayName(mosqueNames[camiName].name);
        if (mosqueNames[camiName].ownLogo) {
          setOwnLogoTitle(mosqueNames[camiName].logoName);
        }
      } else {
        setDisplayName(camiName);
        setOwnLogoTitle("");
      }
    }

    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Row className="background py-2">
      <Col xs={3} className="d-flex justify-content-center align-items-center">
        {mosqueObject?.ownLogo && (
          <Image
            src={ownLogoTitle}
            alt="Logo"
            className="d-inline-block align-top"
            width="50%"
            height="auto"
          />
        )}
         {mosqueObject?.ditib && (
        <Image
          src="ditib_logo_new.png"
          alt="Logo"
          className="d-inline-block align-top"
          width="50%"
          height="auto"
          />
      )}
      </Col>
      <Col xs={6}>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h1 className="text mb-0">{displayName}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h1 className="text-smaller  mb-0">
              Namaz Vakitleri - Gebetszeiten - أوقات الصلاة
            </h1>
          </Col>
        </Row>
      </Col>
      <Col
        xs={3}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <h1 className="text-smaller mb-0">
          {currentDateTime.toLocaleDateString()}
        </h1>
        <h1 className="time mb-0">
          {currentDateTime.toLocaleTimeString().substring(0, 5)}
        </h1>
      </Col>
    </Row>
  );
}

export default Header;
