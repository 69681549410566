import { Row, Col, Card, Carousel } from "react-bootstrap";
import "../styles/prayer-time-boxes.css";
import { useEffect, useRef, useState } from "react";
import { ApiResponse } from "../interface/api-response";
import PrayerTimeCal from "./prayer-time-cal";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ApiResponseAyet } from "../interface/api-response-ayet";

function PrayerTimeBoxes() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState<ApiResponse | undefined>(undefined);
  const [ayetData, setAyetdata] = useState<ApiResponseAyet | undefined>(
    undefined
  );
  const [SabahNamazKilinis, setSabahNamazKilinis] = useState("");
  const [subtractFromFajr, setSubtractFromFajr] = useState(0);
  const fajrRef = useRef<HTMLDivElement>(null);
  const dhuhrRef = useRef<HTMLDivElement>(null);
  const asrRef = useRef<HTMLDivElement>(null);
  const maghribRef = useRef<HTMLDivElement>(null);
  const ishaRef = useRef<HTMLDivElement>(null);


  const mosqueNames: { [key: string]: number } = {
    elmshorn: 45,
    bergedorf: 45,
    wilhelmsburg:45
  };


  const changeCardBackgroundColor = (prayerTime: string) => {
    const orginalColor = "#7393B3";

    switch (prayerTime) {
      case "fajr":
        if (fajrRef.current && ishaRef.current) {
          ishaRef.current.style.backgroundColor = orginalColor;
        }
        break;
      case "sunrise":
        if (fajrRef.current) {
          fajrRef.current.style.backgroundColor = "#D10000";
        }
        break;
      case "dhuhr":
        if (dhuhrRef.current && fajrRef.current) {
          fajrRef.current.style.backgroundColor = orginalColor;
          dhuhrRef.current.style.backgroundColor = "#D10000";
        }
        break;
      case "asr":
        if (asrRef.current && dhuhrRef.current) {
          dhuhrRef.current.style.backgroundColor = orginalColor;
          asrRef.current.style.backgroundColor = "#D10000";
        }
        break;
      case "maghrib":
        if (maghribRef.current && asrRef.current) {
          asrRef.current.style.backgroundColor = orginalColor;
          maghribRef.current.style.backgroundColor = "#D10000";
        }
        break;
      case "isha":
        if (ishaRef.current && maghribRef.current) {
          maghribRef.current.style.backgroundColor = orginalColor;
          ishaRef.current.style.backgroundColor = "#D10000";
        }
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    let cityData = { city: user.username };
    if(user.username === "ayasofya-wilhelmsburg"){
      cityData = { city: "wilhelmsburg" };
    }

    try {
      const response = await axios.post(
        "https://m33yzwapu6.execute-api.eu-central-1.amazonaws.com/prayertimes",
        JSON.stringify(cityData)
      );
      const responseAyet = await axios.get(
        "https://m33yzwapu6.execute-api.eu-central-1.amazonaws.com/ayet"
      );
      const responseData: ApiResponse | null | undefined = response.data;
      if (responseAyet.data !== undefined) {
        setAyetdata(responseAyet.data);
      }
      if (responseData?.Item !== undefined) {
        setData(responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();

    if (user && user.username) {
      let camiName: string = user.username;
      if(user.username === "ayasofya-wilhelmsburg"){
        camiName = "wilhelmsburg"
      }
      if (camiName in mosqueNames) {
        setSubtractFromFajr(mosqueNames[camiName]);
      } else {
        setSubtractFromFajr(0);
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      let fajrPrayTime = new Date();
      fajrPrayTime.setHours(
        parseInt(data?.Item.sunrise.S.substring(0, 2), 10),
        parseInt(data?.Item.sunrise.S.substring(3), 10)
      );
      fajrPrayTime.setMinutes(fajrPrayTime.getMinutes() - subtractFromFajr);
      let fajrPrayTimeHoursString = fajrPrayTime.getHours().toString();
      let fajrPrayTimeMinutesString = fajrPrayTime.getMinutes().toString();
      if (fajrPrayTime.getHours() < 10) {
        fajrPrayTimeHoursString = 0 + fajrPrayTimeHoursString;
      }
      if (fajrPrayTime.getMinutes() < 10) {
        fajrPrayTimeMinutesString = 0 + fajrPrayTimeMinutesString;
      }
      setSabahNamazKilinis(
        fajrPrayTimeHoursString + ":" + fajrPrayTimeMinutesString
      );
    }
  }, [data]);

  return (
    <>
      <Row>
        <Col xs={12} style={{ padding: 0 }}>
          <PrayerTimeCal
            prayerTimesFromBackend={data}
            onChangeCardBackgroundColor={changeCardBackgroundColor}
          ></PrayerTimeCal>
        </Col>
      </Row>
      <Row>
        <Col xs={9}>
          <Row>
            <Col xs={6}>
              <Card
                ref={fajrRef}
                className="box d-flex  justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Imsak</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small-2">
                        Morgengebet - الفجر
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime">{data?.Item.fajr.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Güneş</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small-2">
                        Sonnenaufgang - الشروق
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime ">{data?.Item.sunrise.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                ref={dhuhrRef}
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Öğle</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small">
                        Mittaggebet - الظهر
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime ">{data?.Item.dhuhr.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                ref={asrRef}
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Ikindi</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small-2">
                        Nachmittaggebet - العصر
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime ">{data?.Item.asr.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                ref={maghribRef}
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Akşam</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small">
                        Abendgebet - المغرب
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime ">{data?.Item.maghrib.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                ref={ishaRef}
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={6}>
                    <Row>
                      <Col className="prayertime-title">Yatsı</Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small">
                        Nachtgebet - العشاء
                      </Col>
                    </Row>
                  </Col>

                  <Col className="prayertime ">{data?.Item.isha.S}</Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12}>
              <Card
                className="box justify-content-center"
                style={{ backgroundColor: "#7393B3" }}
                text="white"
              >
                <Row className="text-center d-flex align-items-center">
                  <Col xs={9}>
                    <Row>
                      <Col className="prayertime-title">
                        Sabah namazı kılınış saati
                      </Col>
                    </Row>
                    <Row>
                      <Col className="prayertime-title-small">
                        Morgengebetszeit in der Moschee - صلاة الصبح
                      </Col>
                    </Row>
                  </Col>
                  <Col className="prayertime " xs={3}>
                    {SabahNamazKilinis}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={3} md={3} lg={3}>
          <Card
            className="text-center prayertime"
            style={{ height: "63vh", backgroundColor: "#7393B3" }}
            text="white"
          >
            <div className="prayertime-title">Günün ayeti</div>
            <div className="ayet">{ayetData?.Item.ayet.S}</div>
            <div className="prayertime-title-small">{ayetData?.Item.source.S}</div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PrayerTimeBoxes;
