import React, { useEffect, useState } from "react";
import "../styles/prayer-time-cal.css";
import { ApiResponse } from "../interface/api-response";

interface PrayerTimeCalProps {
  prayerTimesFromBackend?: ApiResponse;
  onChangeCardBackgroundColor: (prayerTime: string) => void;
}

interface TimeToPrayerData {
  text: string;
  timeLeftPrayerForm: string;
  dateObjekt: Date;
}

function calculateTimeToPrayer(
  prayerTime: string,
  prayerName: string
): TimeToPrayerData {
  let hours: number;
  let minutes: number;

  if (prayerTime.substring(0, 1) === "0") {
    hours = parseInt(prayerTime.substring(1, 2), 10);
  } else {
    hours = parseInt(prayerTime.substring(0, 2), 10);
  }

  if (prayerTime.substring(3, 4) === "0") {
    minutes = parseInt(prayerTime.substring(4, 5), 10);
  } else {
    minutes = parseInt(prayerTime.substring(3, 5), 10);
  }

  const today = new Date();
  const startTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    hours,
    minutes,
    0
  );

  // Falls die Gebetszeit bereits vergangen ist, setze das Datum auf morgen
  if (startTime < today) {
    startTime.setDate(today.getDate() + 1);
  }

  const timeLeft = startTime.getTime() - Date.now();

  const secondsLeft = Math.floor(timeLeft / 1000);
  const hoursLeft = Math.floor(secondsLeft / 3600);
  const minutesLeft = Math.floor((secondsLeft % 3600) / 60);
  const seconds = secondsLeft % 60;

  const timeLeftPrayer = new Date();
  timeLeftPrayer.setHours(hoursLeft, minutesLeft, seconds);

  const timeLeftPrayerForm = timeLeftPrayer.toLocaleTimeString();

  return {
    text: prayerName,
    timeLeftPrayerForm,
    dateObjekt: timeLeftPrayer,
  };
}

const PrayerTimeCal: React.FC<PrayerTimeCalProps> = ({
  prayerTimesFromBackend,
  onChangeCardBackgroundColor
}) => {
  const [prayerTimes, setPrayerTimes] = useState<{
    fajr: string,
    dhuhr: string,
    asr: string,
    maghrib: string,
    isha: string,
    sunrise: string
  }|null>(null);

  const [timeToNextPrayer, setTimeToNextPrayer] = useState("");

  useEffect(() => {
    if (prayerTimesFromBackend) {
      setPrayerTimes({
        fajr: prayerTimesFromBackend.Item.fajr.S,
        dhuhr: prayerTimesFromBackend.Item.dhuhr.S,
        asr: prayerTimesFromBackend.Item.asr.S,
        maghrib: prayerTimesFromBackend.Item.maghrib.S,
        isha: prayerTimesFromBackend.Item.isha.S,
        sunrise: prayerTimesFromBackend.Item.sunrise.S,
      });
    }
  }, [prayerTimesFromBackend]);

  useEffect(() => {if (prayerTimes != null) {

    const intervalId = setInterval(() => {
    // Berechne die verbleibende Zeit für jedes Gebet und setze den geringsten Wert in den State
    const fajrTime = calculateTimeToPrayer(prayerTimes.fajr, "fajr");
    const dhuhrTime = calculateTimeToPrayer(prayerTimes.dhuhr, "dhuhr");
    const asrTime = calculateTimeToPrayer(prayerTimes.asr, "asr");
    const maghribTime = calculateTimeToPrayer(prayerTimes.maghrib, "maghrib");
    const ishaTime = calculateTimeToPrayer(prayerTimes.isha, "isha");
    const sunriseTime = calculateTimeToPrayer(prayerTimes.sunrise, "sunrise");

    const minTime = Math.min(
      (fajrTime.dateObjekt.getHours() * 3600) + (fajrTime.dateObjekt.getMinutes() * 60) + fajrTime.dateObjekt.getSeconds(),
      (dhuhrTime.dateObjekt.getHours() * 3600) + (dhuhrTime.dateObjekt.getMinutes() * 60) + dhuhrTime.dateObjekt.getSeconds(),
      (asrTime.dateObjekt.getHours() * 3600) + (asrTime.dateObjekt.getMinutes() * 60) + asrTime.dateObjekt.getSeconds(),
      (maghribTime.dateObjekt.getHours() * 3600) + (maghribTime.dateObjekt.getMinutes() * 60) + maghribTime.dateObjekt.getSeconds(),
      (ishaTime.dateObjekt.getHours() * 3600) + (ishaTime.dateObjekt.getMinutes() * 60) + ishaTime.dateObjekt.getSeconds(),
      (sunriseTime.dateObjekt.getHours() * 3600) + (sunriseTime.dateObjekt.getMinutes() * 60) + sunriseTime.dateObjekt.getSeconds(),
    );

    

     // Switch-Anweisung, um den Namen des Gebets zu bestimmen
     switch (minTime) {
      case (fajrTime.dateObjekt.getHours() * 3600) + (fajrTime.dateObjekt.getMinutes() * 60) + fajrTime.dateObjekt.getSeconds():
        setTimeToNextPrayer(fajrTime.timeLeftPrayerForm)
        onChangeCardBackgroundColor(ishaTime.text)
        break;
      case (sunriseTime.dateObjekt.getHours() * 3600) + (sunriseTime.dateObjekt.getMinutes() * 60) + sunriseTime.dateObjekt.getSeconds():
          setTimeToNextPrayer(dhuhrTime.timeLeftPrayerForm)
          onChangeCardBackgroundColor(sunriseTime.text)
          break;
      case  (dhuhrTime.dateObjekt.getHours() * 3600) + (dhuhrTime.dateObjekt.getMinutes() * 60) + dhuhrTime.dateObjekt.getSeconds():
        setTimeToNextPrayer(dhuhrTime.timeLeftPrayerForm)
        onChangeCardBackgroundColor("")
        break;
      case (asrTime.dateObjekt.getHours() * 3600) + (asrTime.dateObjekt.getMinutes() * 60) + asrTime.dateObjekt.getSeconds():
        setTimeToNextPrayer(asrTime.timeLeftPrayerForm)
        onChangeCardBackgroundColor(dhuhrTime.text)
        break;
      case (maghribTime.dateObjekt.getHours() * 3600) + (maghribTime.dateObjekt.getMinutes() * 60) + maghribTime.dateObjekt.getSeconds():
        setTimeToNextPrayer(maghribTime.timeLeftPrayerForm)
        onChangeCardBackgroundColor(asrTime.text)
        break;
      case (ishaTime.dateObjekt.getHours() * 3600) + (ishaTime.dateObjekt.getMinutes() * 60) + ishaTime.dateObjekt.getSeconds():
        setTimeToNextPrayer(ishaTime.timeLeftPrayerForm);
        onChangeCardBackgroundColor(maghribTime.text)
        break;
      default:
        break;
    }},1000)


    return () => clearInterval(intervalId);}
  }, [prayerTimes]);

  return (
    <div>
      <h1 className="timeToNextPrayer">{timeToNextPrayer}</h1>
    </div>
  );
};

export default PrayerTimeCal;
